@import "./var.scss";

body {
    background-color: #f5f5f5;
    padding-top: 48px;

    @media screen and (min-width: $breakpoint-medium) {
        padding-top: 66px;
    }
}

iframe {
    min-height: 768px;
    width: 100%;
}

.text-main-yellow {
    color: $color-main-yellow;
}

.flex-mid-center {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.captcha-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 164px;
}